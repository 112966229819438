const createBearer = token => `Bearer ${token}`;

const whitelist = [/sign_in\/?$/, /refresh_token\/?$/];

function installInterceptors(axios, store) {
  axios.interceptors.request.use(req => interceptRequest(store, req));
  axios.interceptors.response.use(null, error => handleError(axios, store, error));
}

async function interceptRequest(store, req) {
  const latestToken = store.getters["auth/latestToken"];

  const token = latestToken && latestToken.jwtToken;
  if (token && typeof token === "string") {
    req.headers["Authorization"] = createBearer(token);
  }
  return req;
}

async function handleError(axios, store, error) {
  const latestToken = store.getters["auth/latestToken"];
  const refreshToken = latestToken && latestToken.refreshToken;
  const isTokenInvalid = error.response && error.response.status === 401;

  // Skip whitelisted urls from token refresh
  if (error.config && error.config.url) {
    const url = `${error.config.url}`;
    if (whitelist.some(regex => regex.test(url))) {
      throw error;
    }
  }

  // Retry request only on token authentication failure
  if (refreshToken && refreshToken.length > 7 && isTokenInvalid && error.config) {
    const eventId = store.state.eventId;
    await store.dispatch("auth/refreshToken", eventId);
    if (store.getters["auth/latestToken"]) {
      return axios.request(error.config);
    }
  }

  // Rethrow the error to allow chainable catches
  throw error;
}

// Auth interceptors works as Vuex plugin
export function install(axios) {
  return function (store) {
    installInterceptors(axios, store);
  };
}

export default {
  install,
};
